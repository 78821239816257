import { PageOptions } from './page-options.model';
import {
  CreateSimulatorRequest,
  SimulatorResponse,
  SimulatorSelectList,
  UpdateSimulatorRequest,
} from './simulators.model';

export class ListLocationResponse {
  data?: LocationResponse[];
  pageOptions?: PageOptions;

  constructor(model: ListLocationResponse) {
    this.data = model.data || (null as Array<LocationResponse>);
    this.pageOptions = model.pageOptions || (null as PageOptions);
  }
}

export class LocationResponse {
  id?: number;
  name?: string;
  addressStreet?: string;
  addressCity?: string;
  addressState?: string;
  addressZipcode?: string;
  addressCountry?: string;
  isBayManager?: boolean;
  isActive?: boolean;
  simulators?: SimulatorResponse[];

  constructor(model: LocationResponse) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.addressStreet = model.addressStreet || '';
    this.addressCity = model.addressCity || '';
    this.addressState = model.addressState || '';
    this.addressZipcode = model.addressZipcode || '';
    this.addressCountry = model.addressCountry || '';
    this.isBayManager = model.isBayManager;
    this.isActive = model.isActive || true;
    this.simulators = model.simulators || (null as Array<SimulatorResponse>);
  }
}

export class CreateLocationRequest {
  name?: string;
  addressStreet?: string;
  addressCity?: string;
  addressState?: string;
  addressZipcode?: string;
  addressCountry?: string;
  isActive?: boolean;
  companyId?: number;
  simulators?: CreateSimulatorRequest[];

  constructor(model: CreateLocationRequest) {
    this.name = model.name || '';
    this.addressStreet = model.addressStreet || '';
    this.addressCity = model.addressCity || '';
    this.addressState = model.addressState || '';
    this.addressZipcode = model.addressZipcode || '';
    this.addressCountry = model.addressCountry || '';
    this.isActive = model.isActive || true;
    this.companyId = model.companyId || 0;
    this.simulators =
      model.simulators || (null as Array<CreateLocationRequest>);
  }
}

export class CreateLocationResponse {
  id?: number;

  constructor(model: CreateLocationResponse) {
    this.id = model.id || 0;
  }
}

export class UpdateLocationRequest {
  id?: number;
  name?: string;
  addressStreet?: string;
  addressCity?: string;
  addressState?: string;
  addressZipcode?: string;
  addressCountry?: string;
  isActive?: boolean;
  simulators?: UpdateSimulatorRequest[];

  constructor(model: UpdateLocationRequest) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.addressStreet = model.addressStreet || '';
    this.addressCity = model.addressCity || '';
    this.addressState = model.addressState || '';
    this.addressZipcode = model.addressZipcode || '';
    this.addressCountry = model.addressCountry || '';
    this.isActive = model.isActive || true;
    this.simulators =
      model.simulators || (null as Array<UpdateSimulatorRequest>);
  }
}

export class DeleteLocationRequest {
  id?: number;

  constructor(model: DeleteLocationRequest) {
    this.id = model.id || 0;
  }
}

export class LocationSelection {
  id?: number;
  name?: string;
  isActive?: boolean;

  constructor(model: LocationSelection) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.isActive = model.isActive;
  }
}

export class LocationWithSimulatorSelection {
  id?: number;
  name?: string;
  isActive?: boolean;
  simulators?: SimulatorSelectList[];

  constructor(model: LocationWithSimulatorSelection) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.isActive = model.isActive;
    this.simulators = model.simulators || (null as any);
  }
}

export class LocationSelectList {
  id?: number;
  name?: string;

  constructor(model: LocationSelectList) {
    this.id = model.id || 0;
    this.name = model.name || '';
  }
}
